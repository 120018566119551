


























import { defineComponent } from "@vue/composition-api";
import { Cell, CellGroup, Dialog, Field, Toast } from "vant";
import axios from "@/helpers/axios";

export default defineComponent({
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field
  },
  data() {
    return {
      name: "",
      phone: "",
      manageInfo: "",
      showProfitCheck: false,
      showServicePhoneEdit: false,
      servicePhone: "",
      showServicePhoneEditDialog: false,
      thresholdBatteryVDistance: 0,
      thresholdBatteryVDistanceEdit: 0,
      showThresholdBatteryVDistanceDialog: false,
      thresholdBatteryVDistanceHint: "请输入80-1000之间的数值, 或是0关闭报警",
      commitHash: __COMMIT_HASH__
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const res = await axios
        .post("/api/manage/getHelpInfo")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!res) return;
      this.name = res.name;
      this.phone = res.phone;
      this.manageInfo = res.manageInfo;
      this.showProfitCheck = res.showProfitCheck;
      this.showServicePhoneEdit = res.showServicePhoneEdit;
      this.servicePhone = res.servicePhone;
      this.thresholdBatteryVDistance = res.thresholdBatteryVDistance;
      this.thresholdBatteryVDistanceEdit = res.thresholdBatteryVDistance;
    },
    async updateServicePhone() {
      const res = await axios
        .post("/api/manage/business/updateServicePhone", { servicePhone: this.servicePhone })
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      Toast.success("修改成功");
    },
    async updateThresholdBatteryVDistance() {
      if (this.thresholdBatteryVDistanceEdit !== 0 && (this.thresholdBatteryVDistanceEdit < 80 || this.thresholdBatteryVDistanceEdit > 1000)) {
        Toast.fail(this.thresholdBatteryVDistanceHint);
        return;
      }
      const res = await axios
        .post("/api/manage/business/updateThresholdBatteryVDistance", { thresholdBatteryVDistance: this.thresholdBatteryVDistanceEdit })
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      Toast.success("修改成功");
      this.thresholdBatteryVDistance = this.thresholdBatteryVDistanceEdit;
    },
    toVideoIntro() {
      this.$toMiniAppWeb("https://app.letfungo.com/intro/intro.html");
    },
    toProfitConfigCheck() {
      this.$toMiniAppWeb("/web/manager/business/profit_config_list_manager?hideReplace=1&showIntro=1");
    },
    thresholdBatteryVDistanceInfo() {
      return this.thresholdBatteryVDistance === 0 ? "关闭" : this.thresholdBatteryVDistance + "mv";
    }
  }
});
